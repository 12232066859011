.egw {
    #buttons {
        text-align: center;
        margin: 2rem;

        a {
            padding: 8px 16px;
            margin: 5px;
            background-clip: border-box;
            background-size: 300%;
            border-radius: 8px;
            color: inherit;
            text-decoration: none;
        }
    }

    td {
        padding: 5px 20px 5px 0px;
        border-top: 1px solid #777777;
    }
}