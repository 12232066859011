html {
    background-color: #161616;
    color: white;
    
    &.light {
        background-color: #f9f9f9;
        color: black;
    }
}

body {
    margin: 0;
    height: 100vh;

    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    min-height: 100%;
}

#view {
    padding: 2rem 0;
    margin: 0 auto;
    width: min(calc(100% - 2rem), 1000px);
}

div.container {
    margin-bottom: 5em;
}


html {
    --gradient: linear-gradient(90deg, rgb(255, 153, 0), rgb(252, 130, 8),
    rgb(249, 107, 16), rgb(246, 85, 23), rgb(243, 62, 31), rgb(240, 39, 39),
    rgb(231, 35, 68), rgb(221, 32, 97), rgb(212, 28, 127),
    rgb(203, 24, 156), rgb(193, 21, 185), rgb(184, 17, 214));
}

h1, h3, a {
    background-image: var(--gradient);
    background-clip: text;
    color: transparent;
}

@keyframes animateGradient {
    from { background-position: left; }
    to { background-position: right; }
}

h1 {
    font-size: 4em;
    text-align: center;
    background-size: 200%;
    animation: animateGradient linear infinite alternate 10s;
}

h3 {
    font-size: 1.5em;
    margin: 12px 0;
    color: inherit;
    transition: color 1s;
}

a {
    font-size: 1.1em;
    font-weight: 600;
    background-size: 500%;
    transition: background-position 1s;

    &:hover {
        background-position: right;
    }
}

img {
    border-radius: 8px;
    max-width: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    color: #ff002b;
}


#top-bar {
    position: absolute;
    width: min(calc(100% - 2rem), 1000px);

    button {
        font-size: 30px;
        padding: 0;
        cursor: pointer;
        background: none;
        border: none;
        outline: none;
    }

    #home {
        position: absolute;
        cursor: pointer;
    }

    #theme-switcher {
        position: relative;
        left: calc(100% - 3.3rem);
    }
}

::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    background-color: #333333;
}

::-webkit-scrollbar-thumb {
    background-color: #555555;

    &:hover {
        background-color: #5e5e5e;
    }
}

html.light {
    ::-webkit-scrollbar-track {
        background-color: #c2c2c2;
    }
    
    ::-webkit-scrollbar-thumb {
        background-color: #878787;
    
        &:hover {
            background-color: #727272;
        }
    }
}