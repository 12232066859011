.home {
    li {
        padding: 2px 0 0 20px;
        list-style-type: "\1F517";
    }
}

#projects {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-auto-rows: 1fr;
    gap: 25px;
    margin-bottom: 0;
    
    img {
        width: 100%;
        height: 200px;
        object-fit: cover;

        &.top {
            object-position: top left;
        }
    }

    div {
        width: fit-content;
        margin: 0;
        padding: 10px;
        padding-bottom: 0;
        
        border-radius: 10px + 8px;
        background-color: #262626;
        cursor: pointer;
        transition: all .5s;

        &:hover {
            margin: -8px;
            padding: 18px;
            padding-bottom: 8px;
            border-radius: 18px + 8px;

            h3 {
                color: transparent;
            }
        }
    }
}

html.light #projects div {
    background-color: #e9e9e9;
}